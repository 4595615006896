import React from 'react';
import { IconButton, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { AddCircleOutline } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';
import { isValidUrl } from '../util/formatValidators';
import DeleteAlert from './DeleteAlert';
import api from '../services';

/**
 * Datatable and forms to manage ressources of the databse.
 * @prop {Object} classes
 * @prop {Function} displaySnackbar function that displays the snackbar
 * for info and error messages
 */
class RessourcesManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newVideo: { title: '', description: '', file: '' },
            newPdf: { title: '', description: '', file: '' },
            tableData: [],
            inputKey: Date.now(),
            deleteAlerteIsOpen: false
        };
    }

    /**
     * Fetches the ressources data from the API.
     */
    getRessourcesData = () => {
        const { displaySnackbar } = this.props;
        api.getAllRessources()
            .then(res => {
                this.setState({
                    tableData: res.data
                });
            })
            .catch(err => {
                displaySnackbar(
                    true,
                    `Erreur lors de la récupération des données des ressources : ${
                        err.response ? err.response.data.message : err.message
                    }`
                );
            });
    };

    /**
     * Handles deletion of a ressource row in the datatable.
     * @param {Object} rowData row data passed on by onRowsDelete
     */
    deleteRessourceRow = rowData => {
        const { tableData } = this.state;
        const { displaySnackbar } = this.props;
        const ressourceToDelete = tableData[rowData.dataIndex];
        api.deleteRessource(ressourceToDelete._id)
            .then(res => {
                if (res.status === 200 && res.data) {
                    this.setState({ tableData: res.data });
                    displaySnackbar(false, 'Ressource supprimée avec succès');
                }
            })
            .catch(err => {
                displaySnackbar(
                    true,
                    `Erreur lors de la tentative de suppression de ressource : ${
                        err.response ? err.response.data.message : err.message
                    }`
                );
            });
    };

    /**
     * Adds a new PDF ressource in the database using the corresponding form.
     */
    addNewPdfRessource = () => {
        const { displaySnackbar } = this.props;
        const { newPdf } = this.state;
        const formData = new FormData();
        formData.append('title', newPdf.title);
        formData.append('description', newPdf.description);
        formData.append('pdf', newPdf.file);
        api.addPdfRessource(formData)
            .then(res => {
                if (res.status === 200) {
                    displaySnackbar(false, 'Nouvelle ressource PDF ajoutée avec succès');
                    this.getRessourcesData();
                }
            })
            .catch(err => {
                displaySnackbar(
                    true,
                    `Erreur lors de la tentative d'ajout de la ressource PDF : ${
                        err.response ? err.response.data.message : err.message
                    }`
                );
            });
        this.setState({ newPdf: { title: '', description: '', file: '' }, inputKey: Date.now() });
    };

    /**
     * Adds a new video ressource in the database using the corresponding form.
     */
    addNewVideoRessource = () => {
        const { displaySnackbar } = this.props;
        const { newVideo } = this.state;
        api.addVideoRessource(newVideo.title, newVideo.description, newVideo.file)
            .then(res => {
                if (res.status === 200) {
                    displaySnackbar(false, 'Nouvelle ressource vidéo ajoutée avec succès');
                    this.getRessourcesData();
                }
            })
            .catch(err => {
                displaySnackbar(
                    true,
                    `Erreur lors de la tentative d'ajout de la ressource vidéo : ${
                        err.response ? err.response.data.message : err.message
                    }`
                );
            });
        this.setState({ newVideo: { title: '', description: '', file: '' } });
    };

    /**
     * Handles a click on a cell of the datatable, and possibly downloads
     * the corresponding PDF file.
     * @param {String} text text content of the cell
     * @param {Object} cellData information about the clicked cell (including row and column index)
     */
    handleCellClick = (text, cellData) => {
        const { tableData } = this.state;
        const { displaySnackbar } = this.props;
        const dataRow = tableData[cellData.dataIndex];
        if (dataRow.type === 'pdf') {
            api.downloadPdf(dataRow._id)
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', text);
                    document.body.appendChild(link);
                    link.click();
                    displaySnackbar(false, 'Téléchargement de la ressource PDF');
                })
                .catch(err => {
                    displaySnackbar(
                        true,
                        `Erreur lors de téléchargement de la ressource PDF : ${
                            err.response ? err.response.data.message : err.message
                        }`
                    );
                });
        }
    };

    componentDidMount = () => {
        this.getRessourcesData();
    };

    render() {
        const { classes } = this.props;
        const { newVideo, newPdf, tableData, inputKey, deleteAlerteIsOpen } = this.state;

        /** Remove the pdf filename timestamp from the display */
        const tableDataWithNicePDFTitles = tableData.map(dataRow => {
            if (dataRow.type === 'pdf') {
                const fileNameSplit = dataRow.file.split('-');
                const niceFileName = `${fileNameSplit.slice(0, -1).join('')}.pdf`;
                return { ...dataRow, file: niceFileName };
            }
            return dataRow;
        });

        return (
            <div className={classes.container}>
                <DeleteAlert
                    isOpen={deleteAlerteIsOpen}
                    handleClose={() => this.setState({ deleteAlerteIsOpen: false })}
                    handleConfirm={() => {
                        this.rowsToDelete.data.forEach(this.deleteRessourceRow);
                        this.setState({ deleteAlerteIsOpen: false });
                    }}
                />
                <MUIDataTable
                    title="Ressources Actives"
                    data={tableDataWithNicePDFTitles}
                    columns={[
                        {
                            name: 'type',
                            label: 'Type de fichier'
                        },
                        {
                            name: 'title',
                            label: 'Titre'
                        },
                        {
                            name: 'description',
                            label: 'Description',
                            options: {
                                sort: false
                            }
                        },
                        {
                            name: 'file',
                            label: 'Fichier',
                            options: {
                                sort: false
                            }
                        }
                    ]}
                    options={{
                        filter: false,
                        print: false,
                        viewColumns: false,
                        rowsPerPageOptions: [10, 20, 50, 100],
                        responsive: 'scroll',
                        onCellClick: this.handleCellClick,
                        onRowsDelete: rowsDeleted => {
                            this.rowsToDelete = rowsDeleted;
                            return this.setState({ deleteAlerteIsOpen: true });
                        }
                    }}
                />
                <div className={classes.addVideo}>
                    <TextField
                        id="videoTitle"
                        label="Titre"
                        helperText="Entrez le titre de la vidéo (max: 25 caractères)"
                        inputProps={{ maxLength: 25 }}
                        value={newVideo.title}
                        onChange={input => {
                            this.setState({ newVideo: { ...newVideo, title: input.target.value } });
                        }}
                        margin="normal"
                        className={classes.titleField}
                    />
                    <TextField
                        id="videoDescription"
                        label="Description"
                        helperText="Entrez la description de la vidéo (max: 150 caractères)"
                        inputProps={{ maxLength: 150 }}
                        value={newVideo.description}
                        onChange={input => {
                            this.setState({
                                newVideo: { ...newVideo, description: input.target.value }
                            });
                        }}
                        margin="normal"
                        className={classes.descriptionField}
                    />
                    <TextField
                        id="videoLink"
                        label="URL YouTube"
                        helperText={
                            !isValidUrl(newVideo.file) && newVideo.file.length > 0
                                ? "Format d'URL incorrect"
                                : "Entrez l'adresse URL de la vidéo"
                        }
                        value={newVideo.file}
                        onChange={input => {
                            this.setState({
                                newVideo: { ...newVideo, file: input.target.value }
                            });
                        }}
                        margin="normal"
                        className={classes.descriptionField}
                    />
                    <IconButton
                        disabled={newVideo.title.length === 0 || !isValidUrl(newVideo.file)}
                        color="secondary"
                        onClick={this.addNewVideoRessource}
                    >
                        <AddCircleOutline className={classes.addItemIcon} />
                        <Typography variant="caption">Ajouter la vidéo</Typography>
                    </IconButton>
                </div>
                <div className={classes.addVideo}>
                    <TextField
                        id="pdfTitle"
                        label="Titre"
                        helperText="Entrez le titre de la fiche (max: 25 caractères)"
                        inputProps={{ maxLength: 25 }}
                        value={newPdf.title}
                        onChange={input => {
                            this.setState({ newPdf: { ...newPdf, title: input.target.value } });
                        }}
                        margin="normal"
                        className={classes.titleField}
                    />
                    <TextField
                        id="pdfDescription"
                        label="Description"
                        helperText="Entrez la description de la fiche (max: 150 caractères)"
                        inputProps={{ maxLength: 150 }}
                        value={newPdf.description}
                        onChange={input => {
                            this.setState({
                                newPdf: { ...newPdf, description: input.target.value }
                            });
                        }}
                        margin="normal"
                        className={classes.descriptionField}
                    />
                    <input
                        type="file"
                        key={inputKey}
                        accept="application/pdf"
                        id="pdf-select-file"
                        className={classes.fileInput}
                        onChange={file => {
                            this.setState({
                                newPdf: { ...newPdf, file: file.target.files[0] }
                            });
                        }}
                    />
                    <IconButton
                        disabled={newPdf.title.length === 0 || newPdf.file.length === 0}
                        color="secondary"
                        onClick={this.addNewPdfRessource}
                    >
                        <AddCircleOutline className={classes.addItemIcon} />
                        <Typography variant="caption">Ajouter la fiche PDF</Typography>
                    </IconButton>
                </div>
            </div>
        );
    }
}

RessourcesManagement.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    displaySnackbar: PropTypes.func.isRequired
};

const styles = theme => ({
    container: {
        marginBottom: 100
    },
    addVideo: {
        textAlign: 'right',
        paddingTop: 30
    },
    descriptionField: {
        width: 220,
        marginTop: 0,
        marginLeft: 15,
        [theme.breakpoints.up('sm')]: {
            width: 350
        }
    },
    titleField: {
        width: 200,
        marginTop: 0
    },
    addItemIcon: {
        width: 35,
        height: 35,
        padding: 3
    },
    fileInput: {
        marginLeft: 15,
        marginTop: 25
    }
});

export default withStyles(styles)(RessourcesManagement);
