export function setCookie(key, value) {
    const newCookie = `${key}=${value};`; // expires=${new Date(Date.now() + 1000 * 60 * 60 * 24 * 30).toUTCString()};
    document.cookie = newCookie;
}

export function deleteCookie(key) {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export function getCookie(key) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${key}=`);
    if (parts.length === 2) {
        return parts
            .pop()
            .split(';')
            .shift();
    }
    return null;
}
