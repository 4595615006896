/**
 * Adds a 0 before the input number if it is a single digit number.
 * @param {Number} number
 */
export function addZero(number) {
    const nbToStr = number.toString();
    if (nbToStr.length === 1) {
        return `0${nbToStr}`;
    }
    return nbToStr;
}

/**
 * Formats the given date as dd/mm/yyyy.
 * @param {Date} date
 */
export function formatDate(date) {
    if (date) {
        return `${addZero(date.getDate())}/${addZero(date.getMonth() + 1)}/${date.getFullYear()}`;
    }
    return '--/--/----';
}

/**
 * Extracts the creation date from a MongoDB object id.
 * @param {String} id MongoDB Object id
 */
export function getDateFromId(id) {
    return new Date(parseInt(id.substring(0, 8), 16) * 1000);
}
