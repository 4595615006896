import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Home, NewPswdForm, PswdEmailForm, AdminPage, EngagConfid } from './containers';
import colors from './util/colors';
import { getCookie } from './util/cookies';

const theme = createMuiTheme({
    palette: {
        common: {
            black: colors.darkText,
            white: colors.background
        },
        primary: {
            main: colors.mainColor
        },
        secondary: {
            light: colors.logoColor,
            main: colors.actionColor,
            dark: colors.mainColor,
            contrastText: colors.background
        },
        background: {
            default: colors.background,
            paper: '#eee'
        }
    }
});

export default function App() {
    return (
        <Router>
            <MuiThemeProvider theme={theme}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/confidentialite" component={EngagConfid} />
                    <Route path="/user/password/reset" component={PswdEmailForm} />
                    <Route path="/user/password/new/:token" component={NewPswdForm} />
                    <Route
                        path="/admin"
                        render={props =>
                            getCookie('bearerToken') != null ? (
                                <AdminPage {...props} />
                            ) : (
                                <Redirect
                                    to={{
                                        pathname: '/'
                                    }}
                                />
                            )
                        }
                    />
                    <Route
                        path="*"
                        render={() => {
                            return (
                                <div>
                                    <h1 style={{ padding: 10 }}>404 Not Found</h1>
                                </div>
                            );
                        }}
                    />
                </Switch>
            </MuiThemeProvider>
        </Router>
    );
}
