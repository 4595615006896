/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { HeaderBar, SnackbarMessage } from '../components';
import api from '../services';

/**
 * Entry point of the app, allows to go to the user and admin interfaces.
 * @param {Object} props
 */
class NewPswdForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canSendNewPswd: true,
            token: null,
            pswdInput: '',
            confirmPswdInput: '',
            openSnackbar: false,
            snackbarIsError: null,
            snackbarMessage: ''
        };
    }

    /**
     * Redirects to the home page.
     */
    redirect = () => {
        const { history } = this.props;
        history.push('/');
    };

    /* checking that the token in the URL is valid, and if not redirecting
    to the home page */
    componentDidMount = () => {
        const { match } = this.props;
        api.checkPswdResetToken(match.params.token)
            .then(res => {
                if (res.status === 200) {
                    this.setState({ token: match.params.token });
                } else {
                    this.redirect();
                }
            })
            .catch(() => {
                this.redirect();
            });
    };

    /**
     * Ensuring that both password inputs match.
     */
    confirmEqualsOriginal = () => {
        const { pswdInput, confirmPswdInput } = this.state;
        return pswdInput === confirmPswdInput;
    };

    /**
     * Ensuring that at least one letter and one digit are present
     * in the new password, and that it's long enough.
     */
    pswdFormatIsGood = () => {
        const { pswdInput } = this.state;
        return /\d/.test(pswdInput) && /[a-zA-Z]/.test(pswdInput) && pswdInput.length > 7;
    };

    /**
     * Submitting the new password to the API.
     */
    submitNewPswd = () => {
        const { pswdInput, token } = this.state;
        api.resetPassword(token, pswdInput)
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        canSendNewPswd: false,
                        openSnackbar: true,
                        snackbarIsError: false,
                        snackbarMessage:
                            'Votre mot de passe a été modifié avec succès. Vous allez être redirigé...'
                    });
                    setTimeout(() => {
                        this.redirect();
                    }, 4000);
                }
            })
            .catch(err => {
                this.setState({
                    openSnackbar: true,
                    snackbarIsError: true,
                    snackbarMessage: `Erreur lors de la tentative de changement de mot de passe : ${
                        err.response ? err.response.data.message : err.message
                    }`
                });
            });
    };

    render() {
        const { classes } = this.props;
        const {
            token,
            pswdInput,
            confirmPswdInput,
            snackbarIsError,
            snackbarMessage,
            openSnackbar,
            canSendNewPswd
        } = this.state;
        return token ? (
            <div>
                <HeaderBar />
                <SnackbarMessage
                    isError={snackbarIsError}
                    text={snackbarMessage}
                    open={openSnackbar}
                    handleClose={() => this.setState({ openSnackbar: false })}
                />
                <div className={classes.contentContainer}>
                    <Typography
                        className={classes.description}
                        variant="h6"
                        align="center"
                        gutterBottom
                    >
                        Choisissez et confirmez le nouveau mot de passe pour votre compte (celui-ci
                        doit comporter entre 8 et 32 caractères, dont au moins une lettre et un
                        chiffre, sans espaces).
                    </Typography>
                    <div className={classes.form}>
                        <TextField
                            id="pswd-input"
                            label="Nouveau mot de passe"
                            className={classes.textField}
                            inputProps={{ type: 'password' }}
                            value={pswdInput}
                            onChange={input => {
                                this.setState({ pswdInput: input.target.value });
                            }}
                            margin="normal"
                        />
                        <TextField
                            id="pswd-input"
                            label="Confirmez le mot de passe"
                            className={classes.textField}
                            inputProps={{ type: 'password' }}
                            value={confirmPswdInput}
                            onChange={input => {
                                this.setState({ confirmPswdInput: input.target.value });
                            }}
                            margin="normal"
                        />
                        <Button
                            disabled={
                                !(
                                    this.confirmEqualsOriginal() &&
                                    this.pswdFormatIsGood() &&
                                    canSendNewPswd
                                )
                            }
                            variant="contained"
                            color="secondary"
                            onClick={this.submitNewPswd}
                        >
                            {this.pswdFormatIsGood()
                                ? this.confirmEqualsOriginal()
                                    ? 'Confirmer le changement de mot de passe'
                                    : 'La confirmation ne correspond pas au nouveau mot de passe'
                                : 'Le mot de passe ne respecte pas les contraintes énoncées ci-dessus'}
                        </Button>
                    </div>
                </div>
            </div>
        ) : (
            <div />
        );
    }
}

NewPswdForm.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const styles = theme => ({
    contentContainer: {
        textAlign: 'center',
        padding: 5,
        marginBottom: '20%',
        [theme.breakpoints.up('md')]: {
            width: 960,
            marginRight: 'auto',
            marginLeft: 'auto'
        }
    },
    form: {
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    description: {
        paddingTop: 100,
        paddingBottom: 100
    },
    textField: {
        width: 280,
        margin: 15
    }
});

export default withStyles(styles)(NewPswdForm);
