import { login, sendPswdResetEmail, checkPswdResetToken, resetPassword } from './authentication';
import { getAllUsersData, registerNewUser, deleteUser } from './users';
import {
    getAllRessources,
    deleteRessource,
    addPdfRessource,
    addVideoRessource,
    downloadPdf
} from './ressources';
import axios from './config';

export default {
    login,
    sendPswdResetEmail,
    checkPswdResetToken,
    resetPassword,
    axios,
    getAllUsersData,
    registerNewUser,
    deleteUser,
    getAllRessources,
    deleteRessource,
    addPdfRessource,
    addVideoRessource,
    downloadPdf
};
