import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import api from '../services';
import { deleteCookie, getCookie } from '../util/cookies';
import { SnackbarMessage, HeaderBar, UsersManagement, RessourcesManagement } from '../components';

/**
 * Page allowing the administrator to create/delete accounts, upload resources to the app.
 * @param {Object} props
 */
class AdminPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openSnackbar: false,
            snackbarIsError: null,
            snackbarMessage: '',
            snackbarDuration: undefined
        };
    }

    displaySnackbar = (isError, text, duration) => {
        this.setState({
            openSnackbar: true,
            snackbarIsError: isError,
            snackbarMessage: text,
            snackbarDuration: duration
        });
    };

    render() {
        const { classes } = this.props;
        const { openSnackbar, snackbarIsError, snackbarMessage, snackbarDuration } = this.state;
        if (getCookie('bearerToken') != null) {
            api.axios.defaults.headers.common.Authorization = `Bearer ${getCookie('bearerToken')}`;
        }
        return (
            <div>
                <HeaderBar
                    isAdmin
                    onLogout={() => {
                        deleteCookie('bearerToken');
                        delete api.axios.defaults.headers.common.Authorization;
                    }}
                />
                <div className={classes.contentContainer}>
                    <SnackbarMessage
                        isError={snackbarIsError}
                        text={snackbarMessage}
                        open={openSnackbar}
                        duration={snackbarDuration}
                        handleClose={() =>
                            this.setState({ openSnackbar: false, snackbarDuration: undefined })
                        }
                    />
                    <Typography className={classes.subtitle} align="left" variant="h4">
                        Gestion des utilisateurs
                    </Typography>
                    <UsersManagement displaySnackbar={this.displaySnackbar} />
                    <Typography className={classes.subtitle} align="left" variant="h4">
                        Gestion des ressources
                    </Typography>
                    <RessourcesManagement displaySnackbar={this.displaySnackbar} />
                </div>
            </div>
        );
    }
}

AdminPage.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired
};

const styles = theme => ({
    contentContainer: {
        textAlign: 'center',
        padding: 10,
        marginBottom: '20%',
        [theme.breakpoints.up('md')]: {
            width: 960,
            marginRight: 'auto',
            marginLeft: 'auto'
        }
    },
    subtitle: {
        paddingTop: 30,
        paddingBottom: 30,
        color: '#444444'
    }
});

export default withStyles(styles)(AdminPage);
