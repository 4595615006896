import axios from './config';

export function getAllRessources() {
    return axios.get('/ressources');
}

export function deleteRessource(id) {
    return axios.delete(`/ressources/${id}`);
}

export function addVideoRessource(title, description, file) {
    return axios.post('/ressources/video', { title, description, file });
}

export function addPdfRessource(formData) {
    return axios.post('/ressources/pdf', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export function downloadPdf(id) {
    return axios.get(`/ressources/pdf/${id}`, { responseType: 'blob' });
}
