/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { setCookie } from '../util/cookies';
import { HeaderBar, AdminAuthDialog, SnackbarMessage } from '../components';
import api from '../services';

/**
 * Entry point of the app, allows to go to the user and admin interfaces.
 * @param {Object} props
 */
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAuthDialog: false,
            openSnackbar: false,
            snackbarIsError: null,
            snackbarMessage: ''
        };
    }

    handleAuthSubmit = (email, password) => {
        const { history } = this.props;
        api.login(email, password)
            .then(res => {
                if (res.status === 200 && res.data.user.admin) {
                    setCookie('bearerToken', res.data.token);
                    api.axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
                    history.push('/admin');
                } else {
                    this.setState({
                        openSnackbar: true,
                        snackbarIsError: true,
                        snackbarMessage: "Vous n'êtes pas autorisé à accéder à ce contenu"
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    this.setState({
                        openSnackbar: true,
                        snackbarIsError: true,
                        snackbarMessage: 'Connexion impossible : identifiants incorrects'
                    });
                } else {
                    this.setState({
                        openSnackbar: true,
                        snackbarIsError: true,
                        snackbarMessage: `Erreur lors de la tentative de connexion : ${
                            err.response ? err.response.data.message : err.message
                        }`
                    });
                }
            });
    };

    render() {
        const { classes, history } = this.props;
        const { openAuthDialog, openSnackbar, snackbarIsError, snackbarMessage } = this.state;
        return (
            <div>
                <SnackbarMessage
                    isError={snackbarIsError}
                    text={snackbarMessage}
                    open={openSnackbar}
                    handleClose={() => this.setState({ openSnackbar: false })}
                />
                <AdminAuthDialog
                    open={openAuthDialog}
                    handleClose={() => this.setState({ openAuthDialog: false })}
                    handleSubmit={this.handleAuthSubmit}
                />
                <HeaderBar />
                <div className={classes.contentContainer}>
                    <Typography
                        className={classes.title}
                        variant="display1"
                        align="center"
                        gutterBottom
                    >
                        Bienvenue sur l&apos;interface de gestion de comptes EliVente
                    </Typography>
                    <Button
                        onClick={() => {
                            history.push('/user/password/reset');
                        }}
                        variant="contained"
                        color="secondary"
                        className={classes.buttons}
                    >
                        Interface utilisateur (gestion du mot de passe)
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.buttons}
                        onClick={() => this.setState({ openAuthDialog: true })}
                    >
                        Interface administrateur
                    </Button>
                    <Typography
                        className={classes.contactMail}
                        variant="subtitle1"
                        align="center"
                        gutterBottom
                    >
                        Pour consulter l&apos;Engagement de Confidentialité concernant
                        l&apos;utilisation de l&apos;application Elivente,{' '}
                        <span
                            className={classes.confidLink}
                            role="link"
                            tabIndex="-1"
                            onClick={() => {
                                history.push('/confidentialite');
                            }}
                        >
                            cliquez ici
                        </span>
                        .
                    </Typography>
                    <Typography
                        className={classes.contactMail}
                        variant="subtitle1"
                        align="center"
                        gutterBottom
                    >
                        Pour toute autre demande, contacter le support par mail à{' '}
                        <span className={classes.mailLink}>elivente@eliform.fr</span>.
                    </Typography>
                </div>
            </div>
        );
    }
}

Home.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    history: PropTypes.object.isRequired
};

const styles = theme => ({
    contentContainer: {
        textAlign: 'center',
        padding: 10,
        marginBottom: '20%',
        [theme.breakpoints.up('md')]: {
            width: 960,
            marginRight: 'auto',
            marginLeft: 'auto'
        }
    },
    title: {
        paddingTop: 40,
        paddingBottom: 20,
        [theme.breakpoints.up('sm')]: {
            paddingTop: 100,
            paddingBottom: 100
        }
    },
    buttons: {
        margin: 20
    },
    contactMail: {
        fontWeight: 300,
        paddingTop: 40,
        [theme.breakpoints.up('sm')]: {
            paddingTop: 100
        }
    },
    mailLink: {
        color: 'blue'
    },
    confidLink: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        textDecoration: 'underline',
        cursor: 'pointer'
    }
});

export default withStyles(styles)(Home);
