import React from 'react';
import { Button, Typography, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { HeaderBar, SnackbarMessage } from '../components';
import { isValidEmail } from '../util/formatValidators';
import api from '../services';

/**
 * Entry point of the app, allows to go to the user and admin interfaces.
 * @param {Object} props
 */
class PswdEmailForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailInput: '',
            openSnackbar: false,
            snackbarIsError: null,
            snackbarMessage: '',
            canSendMail: true
        };
    }

    /**
     * Sends an email at the given address, providing a link to change or
     * reset the account's password.
     */
    sendPswdEmail = () => {
        const { history } = this.props;
        const { emailInput } = this.state;
        api.sendPswdResetEmail(emailInput)
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        canSendMail: false,
                        openSnackbar: true,
                        snackbarIsError: false,
                        snackbarMessage:
                            "Un mail de changement de mot de passe vous a été envoyé à l'adresse saisie. Vous allez être redirigé..."
                    });
                    setTimeout(() => history.push('/'), 4000);
                }
            })
            .catch(err => {
                if (
                    err.response &&
                    err.response.data.message === 'No account with that email address exists'
                ) {
                    this.setState({
                        openSnackbar: true,
                        snackbarIsError: true,
                        snackbarMessage:
                            "Erreur lors de l'envoi du mail de changement de mot de passe : aucun compte ne correspond à l'adresse mail saisie"
                    });
                } else {
                    this.setState({
                        openSnackbar: true,
                        snackbarIsError: true,
                        snackbarMessage: `Erreur lors de la demande d'envoi de mail de changement de mot de passe : ${
                            err.response ? err.response.data.message : err.message
                        }`
                    });
                }
            });
    };

    render() {
        const { classes } = this.props;
        const {
            emailInput,
            openSnackbar,
            snackbarIsError,
            snackbarMessage,
            canSendMail
        } = this.state;
        return (
            <div>
                <HeaderBar />
                <div className={classes.contentContainer}>
                    <SnackbarMessage
                        isError={snackbarIsError}
                        text={snackbarMessage}
                        open={openSnackbar}
                        handleClose={() => this.setState({ openSnackbar: false })}
                    />
                    <Typography
                        className={classes.description}
                        variant="h6"
                        align="center"
                        gutterBottom
                    >
                        Entrez l&apos;adresse mail du compte dont vous voulez récupérer ou changer
                        le mot de passe : un courriel va vous y être envoyé (pensez à vérifier vos
                        spams), veuillez cliquer le lien qui s&apos;y trouve.
                    </Typography>
                    <div className={classes.form}>
                        <TextField
                            id="email-input"
                            label="Adresse mail"
                            className={classes.textField}
                            onChange={input => this.setState({ emailInput: input.target.value })}
                            margin="normal"
                        />
                        <Button
                            disabled={!isValidEmail(emailInput) || !canSendMail}
                            variant="contained"
                            color="secondary"
                            className={classes.sendMailButton}
                            onClick={this.sendPswdEmail}
                        >
                            Envoyer le mail
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

PswdEmailForm.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    history: PropTypes.object.isRequired
};

const styles = theme => ({
    contentContainer: {
        textAlign: 'center',
        padding: 5,
        marginBottom: '20%',
        [theme.breakpoints.up('md')]: {
            width: 960,
            marginRight: 'auto',
            marginLeft: 'auto'
        }
    },
    form: {
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    description: {
        paddingTop: 100,
        paddingBottom: 100
    },
    textField: {
        width: 280,
        margin: 15
    },
    sendMailButton: {
        margin: 25
    }
});

export default withStyles(styles)(PswdEmailForm);
