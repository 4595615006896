import React from 'react';
import { IconButton, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { AddCircleOutline } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';
import { formatDate, getDateFromId } from '../util/dateUtils';
import DeleteAlert from './DeleteAlert';
import { isValidEmail } from '../util/formatValidators';
import api from '../services';

/**
 * Datatable and form to manage users of the databse.
 * @prop {Object} classes
 * @prop {Function} displaySnackbar function that displays the snackbar
 * for info and error messages
 */
class UsersManagement extends React.Component {
    constructor(props) {
        super(props);
        this.rowsToDelete = null;
        this.state = { newEmailAddress: '', tableData: [], deleteAlerteIsOpen: false };
    }

    /**
     * Transforms data received from the API to display in the datatable (containing only non admin users).
     * @param {Object} res response from the API
     */
    mapResData = res => {
        const tableData = res.data.reduce((prevData, item) => {
            if (!item.admin) {
                const creationDate = formatDate(getDateFromId(item._id));
                return [...prevData, { ...item, createdAt: creationDate }];
            }
            return prevData;
        }, []);
        this.setState({ tableData });
    };

    /**
     * Fetches the users data from the API.
     */
    getUsersData = () => {
        const { displaySnackbar } = this.props;
        api.getAllUsersData()
            .then(res => this.mapResData(res))
            .catch(err => {
                displaySnackbar(
                    true,
                    `Erreur lors de la récupération des données des utilisateurs : ${
                        err.response ? err.response.data.message : err.message
                    }`
                );
            });
    };

    /**
     * Handles deletion of an user's row in the datatable.
     * @param {Object} rowData row data passed on by onRowsDelete
     */
    deleteUserRow = rowData => {
        const { tableData } = this.state;
        const { displaySnackbar } = this.props;
        const userToDelete = tableData[rowData.dataIndex];
        api.deleteUser(userToDelete._id)
            .then(res => {
                if (res.status === 200 && res.data) {
                    this.mapResData(res);
                    displaySnackbar(false, "Compte d'utilisateur supprimé");
                }
            })
            .catch(err => {
                displaySnackbar(
                    true,
                    `Erreur lors de la tentative de suppression du compte : ${
                        err.response ? err.response.data.message : err.message
                    }`
                );
            });
    };

    /**
     * Registers a new user in the database using the mail address form.
     */
    registerUser = () => {
        const { displaySnackbar } = this.props;
        const { newEmailAddress } = this.state;
        api.registerNewUser(newEmailAddress)
            .then(res => {
                if (res.status === 200) {
                    const { email } = res.data.user;
                    const pswd = res.data.password;
                    displaySnackbar(
                        false,
                        <div>
                            <p>
                                Compte d&apos;utilisateur créé avec succès (par sécurité, copiez ses
                                identifiants en cas de non-réception du courriel de confirmation).
                            </p>
                            <p>Mail : {email}</p>
                            <p>Mot de passe : {pswd}</p>
                        </div>,
                        null
                    );
                    this.getUsersData();
                }
            })
            .catch(err => {
                displaySnackbar(
                    true,
                    `Erreur lors de la tentative de création du compte : ${
                        err.response ? err.response.data.message : err.message
                    }`
                );
            });
        this.setState({ newEmailAddress: '' });
    };

    componentDidMount = () => {
        this.getUsersData();
    };

    render() {
        const { classes } = this.props;
        const { newEmailAddress, tableData, deleteAlerteIsOpen } = this.state;
        return (
            <div className={classes.container}>
                <DeleteAlert
                    isOpen={deleteAlerteIsOpen}
                    handleClose={() => this.setState({ deleteAlerteIsOpen: false })}
                    handleConfirm={() => {
                        this.rowsToDelete.data.forEach(this.deleteUserRow);
                        this.setState({ deleteAlerteIsOpen: false });
                    }}
                />
                <MUIDataTable
                    title="Comptes actifs"
                    data={tableData}
                    columns={[
                        {
                            name: 'email',
                            label: 'Adresse mail'
                        },
                        {
                            name: 'createdAt',
                            label: 'Date de création'
                        }
                    ]}
                    options={{
                        filter: false,
                        print: false,
                        viewColumns: false,
                        rowsPerPageOptions: [10, 20, 50, 100],
                        responsive: 'scroll',
                        onRowsDelete: rowsDeleted => {
                            this.rowsToDelete = rowsDeleted;
                            return this.setState({ deleteAlerteIsOpen: true });
                        }
                    }}
                />
                <div className={classes.addUser}>
                    <TextField
                        id="text-field"
                        label="Adresse mail"
                        helperText={
                            !isValidEmail(newEmailAddress) && newEmailAddress.length > 0
                                ? "Format d'email incorrect"
                                : "Entrez l'adresse du compte à créer"
                        }
                        value={newEmailAddress}
                        onChange={input => {
                            this.setState({ newEmailAddress: input.target.value });
                        }}
                        margin="normal"
                        className={classes.addItemField}
                    />
                    <IconButton
                        disabled={!isValidEmail(newEmailAddress)}
                        color="secondary"
                        onClick={this.registerUser}
                    >
                        <AddCircleOutline className={classes.addItemIcon} />
                        <Typography variant="caption">Créer le compte</Typography>
                    </IconButton>
                </div>
            </div>
        );
    }
}

UsersManagement.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    displaySnackbar: PropTypes.func.isRequired
};

const styles = theme => ({
    container: {
        marginBottom: 100
    },
    addUser: {
        textAlign: 'right',
        paddingTop: 30
    },
    addItemField: {
        width: 220,
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
            width: 350
        }
    },
    addItemIcon: {
        width: 35,
        height: 35,
        padding: 3
    },
    chipsContainer: {
        minHeight: 35,
        margin: 30,
        backgroundColor: '#fff',
        textAlign: 'center'
    },
    chip: {
        margin: 5
    },
    submitButton: {
        float: 'right'
    }
});

export default withStyles(styles)(UsersManagement);
