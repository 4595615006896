import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8080/api/';

// Set axios config defaults
const axiosInstance = axios.create({
    baseURL,
    timeout: 3000
});

export default axiosInstance;
