import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

function EngagConfid({ classes }) {
    return (
        <div className={classes.contentContainer}>
            <div style={{ textAlign: 'center', paddingTop: 60, paddingBottom: 20 }}>
                <Typography variant="h3">Engagement de confidentialité</Typography>
            </div>
            <Typography variant="h5" className={classes.title} color="primary">
                À propos d&apos;ELIFORM
            </Typography>
            <Typography variant="body2" align="justify">
                ELIFORM est un organisme de formation professionnelle, dont l’adresse est : 7 ter
                rue Viala 94100 Saint-Maur-Des-Fossés. ELIFORM conçoit, vend et réalise des
                formations commerciales sur mesure en présentiel et met en fin de formation à
                disposition des personnes formées, dénommés « Utilisateurs » dans la suite de ce
                document, une application d’aide à la vente fonctionnant sur smartphone, dénommée
                ELIVENTE.
            </Typography>
            <Typography variant="h5" className={classes.title} color="primary">
                Obtention d&apos;un compte ELIVENTE
            </Typography>
            <Typography variant="body2" align="justify">
                A l’issue d’une formation en présentiel, ELIFORM transmet à l’Utilisateur, un
                identifiant et un mot de passe, lui donnant accès à l’application ELIVENTE, pendant
                une durée d’un an (à partir de la date de fin de la formation suivie en présentiel).
            </Typography>
            <Typography variant="h5" className={classes.title} color="primary">
                Stockage des données utilisateur
            </Typography>
            <Typography variant="body2" align="justify">
                Durant cette période d&apos;un an, ELIFORM stocke les adresses mails des Utilisateur
                ainsi que les données textuelles saisies par l’Utilisateur dans l’application
                ELIVENTE, sur notre serveur privé et sécurisé. Lorsqu’un Utilisateur supprime des
                données dans l’application ELIVENTE, ces dernières sont également supprimées sur
                notre serveur.
            </Typography>
            <Typography variant="h5" className={classes.title} color="primary">
                Sécurité et confidentialité des données utilisateur
            </Typography>
            <Typography variant="body2" align="justify">
                Les échanges de données entre l’application ELIVENTE et notre serveur sont sécurisés
                via le protocole HTTPS. ELIFORM s’engage à garder confidentielles les informations
                saisies dans l’application ELIVENTE par l&apos;Utilisateur. ELIFORM s’engage à ne
                pas communiquer à des tiers les informations saisies par l&apos;Utilisateur dans
                l’application ELIVENTE.
            </Typography>
            <Typography variant="h5" className={classes.title} color="primary">
                Résiliation automatique et suppression du compte utilisateur
            </Typography>
            <Typography variant="body2" align="justify">
                A l’issue d’une période d’un an, ELIFORM s’engage à supprimer le compte de
                l&apos;Utilisateur ainsi que les données qu&apos;il aura saisies dans l’application
                ELIVENTE. L’Utilisateur pourra constater cette suppression, en formalisant sa
                demande auprès d’ELIFORM.
            </Typography>
            <Typography variant="h5" className={classes.title} color="primary">
                Droits d&apos;utilisation et confidentialité des identifiants utilisateur
            </Typography>
            <Typography variant="body2" align="justify">
                L’identifiant et le mot de passe, livrés par mail à l’Utilisateur, sont des
                informations strictement personnelles et confidentielles, placées sous la
                responsabilité exclusive de l’Utilisateur. L’Utilisateur ne peut en aucun cas,
                céder, revendre, ni partager son identifiant et son mot de passe. Les droits
                d’utilisation de l’application ELIVENTE sont concédés au seul Utilisateur.
                L’Utilisateur se porte garant auprès d’ELIFORM de l’exécution de cette clause par
                tout autre Utilisateur et répondra de toute utilisation frauduleuse ou abusive des
                codes d’accès.
            </Typography>
        </div>
    );
}

EngagConfid.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = theme => ({
    contentContainer: {
        padding: 10,
        marginBottom: '20%',
        [theme.breakpoints.up('md')]: {
            width: 860,
            marginRight: 'auto',
            marginLeft: 'auto'
        }
    },
    title: {
        paddingTop: 60,
        paddingBottom: 30
    }
});

export default withStyles(styles)(EngagConfid);
