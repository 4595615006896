import React from 'react';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Home, ExitToApp } from '@material-ui/icons';

function leftIcon(isAdmin, onLogout, classes) {
    if (isAdmin) {
        return (
            <IconButton color="inherit" onClick={onLogout} component={Link} to="/">
                <ExitToApp className={classes.homeIcon} />
                <Typography variant="caption" color="inherit" className={classes.logoutText}>
                    (Se déconnecter du compte admin)
                </Typography>
            </IconButton>
        );
    }
    return (
        <IconButton color="inherit" component={Link} to="/">
            <Home className={classes.homeIcon} />
        </IconButton>
    );
}

/**
 * Header displayed on all pages of the application.
 * @param {Object} props
 */
function HeaderBar(props) {
    const { classes, isAdmin, onLogout } = props;
    return (
        <AppBar color="primary" position="sticky">
            <Toolbar className={classes.toolbar}>
                {leftIcon(isAdmin, onLogout, classes)}
                <Typography color="inherit" variant="h4" className={classes.header}>
                    Elivente
                </Typography>
            </Toolbar>
        </AppBar>
    );
}

HeaderBar.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    isAdmin: PropTypes.bool,
    onLogout: PropTypes.func
};

HeaderBar.defaultProps = {
    isAdmin: false,
    onLogout: () => {}
};

const styles = theme => ({
    toolbar: {
        height: 50,
        [theme.breakpoints.up('sm')]: {
            height: 75
        }
    },
    homeIcon: {
        width: 25,
        height: 25,
        marginRight: 3,
        [theme.breakpoints.up('sm')]: {
            width: 40,
            height: 40
        }
    },
    logoutText: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 0
        }
    },
    header: {
        fontWeight: '300'
    }
});

export default withStyles(styles)(HeaderBar);
