import axios from './config';

export function getAllUsersData() {
    return axios.get('/user/all');
}

export function registerNewUser(email) {
    return axios.post('/auth/register', { email });
}

export function deleteUser(id) {
    return axios.delete(`/user/${id}`);
}
