import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import colors from '../util/colors';

/**
 * Displays a temporary toast at the bottom of the screen to inform the user of the outcome
 * of interactions with the API.
 * @param {Object} props
 * @param {Boolean} props.isError whether or not to display an error toast
 * @param {String} props.text the message to display in the toast
 * @param {Boolean} props.open whether or not to display the toast
 * @param {Function} props.handleClose callback function when the toast is closed
 * @param {Number} [props.duration] duration before autoclose in ms
 */
function SnackbarMessage(props) {
    const { isError, text, open, handleClose, classes, duration } = props;
    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            autoHideDuration={duration}
            ContentProps={{
                'aria-describedby': 'message-id',
                className: isError ? classes.containerError : classes.containerSuccess
            }}
            ClickAwayListenerProps={{ onClickAway: () => {} }}
            message={text}
            action={
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={handleClose}
                >
                    <Close />
                </IconButton>
            }
        />
    );
}

SnackbarMessage.propTypes = {
    isError: PropTypes.bool,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    duration: PropTypes.number
};

SnackbarMessage.defaultProps = {
    isError: true,
    duration: 8000
};

const styles = {
    containerError: {
        backgroundColor: colors.negativeOrange
    },
    containerSuccess: {
        backgroundColor: colors.positiveGreen
    }
};

export default withStyles(styles)(SnackbarMessage);
