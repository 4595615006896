const colors = {
    background: '#FFFFFF',
    inactiveTabIcon: 'rgba(255,255,255,0.8)',
    statusBarColor: '#82080C',
    mainColor: '#B50B12',
    logoColor: '#ED1C24',
    actionColor: '#FF4750',
    darkText: '#414141',
    lightText: '#9A9A9A',
    linesColor: '#DBDBDB',
    negativeOrange: '#F25F28',
    positiveGreen: '#29BF6A',
    mediumYellow: '#E6B222'
};

export default colors;
