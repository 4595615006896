/* eslint-disable no-useless-escape */
/**
 * Validates the format for an URL string
 *
 * @export
 * @param {String} url the url to test
 * @returns {Boolean} true if the format is correct
 */
export function isValidUrl(url) {
    const videoLinkRegex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    return videoLinkRegex.test(url);
}
/**
 * Validates the format for an email string
 *
 * @export
 * @param {String} email the email to test
 * @returns {Boolean} true is the format is correct
 */
export function isValidEmail(email) {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
}
