import axios from './config';

export function login(email, password) {
    return axios.post('/auth/login', {
        email,
        password
    });
}

export function sendPswdResetEmail(email) {
    return axios.post('/auth/pswd/reset', { email });
}

export function checkPswdResetToken(token) {
    return axios.get(`/auth/pswd/reset/${token}`);
}

export function resetPassword(token, newPassword) {
    return axios.post(`/auth/pswd/reset/${token}`, { newPassword });
}
