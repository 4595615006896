import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Button
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

class AdminAuthDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = { emailInput: '', pswdInput: '' };
    }

    handleSubmitClick = () => {
        const { handleSubmit } = this.props;
        const { emailInput, pswdInput } = this.state;
        handleSubmit(emailInput, pswdInput);
        this.setState({ emailInput: '', pswdInput: '' });
    };

    render() {
        const { classes, open, handleClose } = this.props;
        const { emailInput, pswdInput } = this.state;
        return (
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: 'white'
                    }
                }}
                open={open}
                onClose={handleClose}
                className={classes.dialogContainer}
            >
                <DialogTitle id="form-dialog-title">Authentification</DialogTitle>
                <DialogContent className={classes.contentContainer}>
                    <DialogContentText>
                        Pour accéder à l&apos;interface administrateur, veuillez vous connecter avec
                        vos identifiants administrateur.
                    </DialogContentText>
                    <TextField
                        id="email-input"
                        label="Adresse mail"
                        inputProps={{ autoFocus: true, type: 'email' }}
                        value={emailInput}
                        onChange={input => {
                            this.setState({ emailInput: input.target.value });
                        }}
                        margin="normal"
                        fullWidth
                    />
                    <TextField
                        id="pswd-input"
                        label="Mot de passe"
                        value={pswdInput}
                        inputProps={{ type: 'password' }}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                this.handleSubmitClick();
                            }
                        }}
                        onChange={input => {
                            this.setState({ pswdInput: input.target.value });
                        }}
                        margin="normal"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        onClick={() => {
                            handleClose();
                            this.setState({ emailInput: '', pswdInput: '' });
                        }}
                        color="secondary"
                    >
                        Annuler
                    </Button>
                    <Button variant="contained" onClick={this.handleSubmitClick} color="secondary">
                        Se connecter
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

AdminAuthDialog.propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

const styles = theme => ({
    contentContainer: {
        paddingBottom: 20,
        [theme.breakpoints.up('sm')]: {
            paddingTop: 50,
            paddingBottom: 75
        }
    }
});

export default withStyles(styles)(AdminAuthDialog);
